import App from '@/vue/App.vue'
import {createApp} from 'vue'
import {store} from './stores/store.js'
import LocomotiveScroll from 'locomotive-scroll';
import $ from "jquery";

if(window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations()
        .then(function(registrations) {
            for(let registration of registrations) {
                registration.unregister();
            }
        });
}

// App main
const main = async () => {

    // Async load the vue module
    const [Vue, Lazysizes] = await Promise.all([
        import(/* webpackChunkName: "vue" */ 'vue'),
        import(/* webpackChunkName: "lazysizes" */ 'lazysizes'),
    ]).then(arr => arr.map(({ default: defaults }) => defaults));

    const app = createApp(App)

    // Mount Vuex
    app.use(store)

    // Mount the app
    const root = app.mount('#page-container')


    return root
}

// Execute async function
main().then((root) => { });

    const scroll = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        resetNativeScroll:false,
        getDirection:true

    });



    new ResizeObserver(() => scroll.update()).observe(document.querySelector("[data-scroll-container]"))


$(document).ready(function() {

    scroll.update();

    var formieElements = $('.formie');
    formieElements.each(function (index, element) {
      var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {

          if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
            scroll.update();
          }
        });
      });
      var config = { childList: true, subtree: true };
      observer.observe(element, config);
    });

    const anchorLinks = document.querySelectorAll(
        'a[href^=\\#]:not([href$=\\#])'
      );

	anchorLinks.forEach((anchorLink) => {
		anchorLink.classList.add('anchorLink');
        let hashval = anchorLink.getAttribute('href');
        let target = document.querySelector(hashval);

        console.log(hashval, target);

        anchorLink.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            scroll.scrollTo(target, {offset:$('#page-header').height()});
        });
	});

    scroll.on('scroll',function(args){
        if(args.scroll.y > ($(window).height() * 60/100)){
            $('.heroheader').removeClass('h-[60vh]').addClass('h-80');
            $("#page-header").addClass('md:-translate-y-full');
            window.dispatchEvent(new Event('headerclosing'));
        }else{
            $("#page-header").removeClass('md:-translate-y-full');
        }

        if(args.direction == 'up'){
            $("#page-header").removeClass('md:-translate-y-full');
        }
    })

    $(document).on('click','[data-searchbtn]',function(){
        scroll.stop();
        $('[data-searchdialog]').toggleClass('pointer-events-none opacity-100 opacity-0');
        $('[data-searchdialogbox]').toggleClass('translate-y-10');
        $('[data-searchdialog]').find('input').focus();
    });

    $(document).on('click', '[data-languageswitch]', function() {
        $(this).find('li.hidden').removeClass('hidden').addClass('tohide');
    });

    $('body:not(.current-lang)').on('click', function() {
        $('body').find('li.tohide').removeClass('tohide').addClass('hidden');
    });


    $(document).on("submit",'[data-role="activities-searchform"]',function(e){
        e.preventDefault();
        applySearch();
    })

    $(document).on("change",'[name=t]',function(e){
        e.preventDefault();
        var newValue = $(this).val();
        if(newValue){
            var oldValues = $('[name=selectedTags]').val().split(',');
            var newValues = oldValues;
            if (!oldValues.includes(newValue)) {
                newValues = [...oldValues, newValue];
            }
            $('[name=selectedTags]').val(newValues.join(','));
            applySearch();
            $(this).val('');
        }
    });
    $(document).on("change",'[name=startDate]',function(e){
            applySearch();
    });

    $(document).on("click",'[data-role="remove-filter"]',function(e){
        var oldValues = $('[name=selectedTags]').val().split(',');
        var valueToRemove = $(this).data('id');
        var newValues = oldValues.filter(value => value.toString() !== valueToRemove.toString());

        $('[name=selectedTags]').val(newValues.join(','));
        $(this).remove();
        applySearch();
    });

    initResetListener();
    initPaginationListeners();

    var imagesLoaded = 0;

    var totalImages = $("img").length;
    $("img").each(function (idx, img) {
        $(this).on("load, error", imageLoaded);
    });

  function imageLoaded() {
    imagesLoaded++;
    if (imagesLoaded == totalImages) {
      allImagesLoaded();
    }
  }

  function allImagesLoaded() {
    scroll.update();
  }
});

function applySearch() {
    const searchParams = new URLSearchParams(window.location.search);
    const searchValue = document.querySelector('[name="searchTerm"]').value;
    if(searchValue){
        searchParams.set('search', searchValue);
    }else{
        searchParams.delete('search');
    }

    const tagValues = document.querySelector('[name=selectedTags]').value;
    if(tagValues){
        searchParams.set('t', tagValues);
    }else{
        searchParams.delete('t');
    }

    if(document.querySelector('[name=startDate]')){
    const startDateValue = document.querySelector('[name=startDate]').value;
        if(startDateValue){
            searchParams.set('startDate', startDateValue);
        }else{
            searchParams.delete('startDate');
        }
    }

    updateView(null, searchParams);
}

function initResetListener(){
    document.querySelectorAll('[data-role="reset-filters"]').forEach((element) => {
        element.addEventListener('click', event => {
            event.preventDefault();
            document.querySelector('[name="searchTerm"]').value = "";
            document.querySelector('[name=selectedTags]').value = "";
            if (document.querySelector('[name=startDate]')) {
                document.querySelector('[name=startDate]').value = "";
            }
            updateView(null, new URLSearchParams());
         });
    });
}

function initPaginationListeners() {
    document.querySelectorAll('[data-role="pagination-btn"]').forEach(element => {
        element.addEventListener('click', event => {
            event.preventDefault();
            switchPage(element.dataset.number);
        });
    })
}

function switchPage(pageNumber) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', pageNumber);
    updateView(null, searchParams, true);
}

function updateView(pathname = null, urlSearchParams:URLSearchParams|null = null, scrollToTop:boolean = false) {
    const searchParams = new URLSearchParams(urlSearchParams ? urlSearchParams.toString() : window.location.search);
    const entryType = document.querySelector('[name="entryType"]').value;
    searchParams.set('entryType',entryType);
    searchParams.set('uri', pathname ?? window.location.pathname);

    setLoaderState(true);

    _renderActivities(searchParams).then(() => {
        initResetListener();
        initPaginationListeners();

        _pushState(pathname, urlSearchParams);
        setLoaderState(false, scrollToTop);
    });
}

function _renderActivities(urlSearchParams:URLSearchParams) {
    console.log(window.location);
    console.log(window.location.pathname);
    console.log(window.location.pathname.split('/')[1]);
    return fetch('/'+window.location.pathname.split('/')[1]+'/?action=site-module/activities/render-cards&' + urlSearchParams.toString())
        .then(res => res.text())
        .then(html => {
            const wrapper = document.querySelector('#card-container');
            if(wrapper){
                wrapper.innerHTML = html;
            }
        })
        .catch((error) => {
            console.error('Error: ', error);
        });
}


function setLoaderState(loading:boolean, scrollToTop:boolean = false) {

    if(!loading && scrollToTop){
        scroll.scrollTo('#card-container');
    }

    if(loading){
        document.documentElement.classList.add("overflow-hidden");
        document.documentElement.classList.add("opacity-20");
        scroll.stop();
    }else{
        document.documentElement.classList.remove("overflow-hidden");
        document.documentElement.classList.remove("opacity-20");
        scroll.start();
    }
}

function _pushState(pathname = null, urlSearchParams = null) {
    const resolvedPathname = pathname ?? window.location.pathname;
    const resolvedUrlSearchParams = urlSearchParams ?? new URLSearchParams(window.location.search);
    const queryString = resolvedUrlSearchParams.toString();

    // make sure the new path starts with slash to prevent appending it to the current path.
    const prependedSlash = resolvedPathname[0] !== '/' ? '/' : '';

    const resolvedUrl = prependedSlash + resolvedPathname + (queryString ? '?' + queryString : '');
    const data = {
        cards: document.querySelector('#card-container').innerHTML
    };

    window.history.pushState(data, document.title, resolvedUrl);
}

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
